@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

html,
body,
#root {
  margin: 0;
  padding: 0;
  text-align: center;
  background: #fff;
  font-family: "Montserrat";
  color: #08272f;
  letter-spacing: 0.5px;
  scroll-behavior: smooth;
  min-height: 100vh;
  max-width: 500px;
  margin: 0 auto;
  user-select: none;
}
.textPrice:first-of-type {
  color: #999;
  text-decoration: line-through;
}
.textPrice {
  width: 70%;
  margin: 10px auto;
  color: #3e8f92;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
h1 {
  font-family: "Playfair Display";
  margin-top: 0;
  color: #1761a4;
}
h3 {
  font-family: "Playfair Display";
  font-size: 25px;
  color: #1761a4;
}
a {
  text-decoration: none;
  color: inherit;
}
p {
  color: #08272f;
  font-size: 14px;
  font-weight: 500;
  padding: 0 20px;
  box-sizing: border-box;
}
img {
  font-size: 0;
  max-width: 100%;
}
.headerImage {
  width: 60%;
  margin: 0 auto;
}
button:disabled {
  opacity: 0.3;
  transition: 0.35s;
}
button {
  background: transparent;
  width: 90%;
  padding: 12px 0px;
  border: none;
  border-radius: 50px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  font-family: "Montserrat";
  transition: 0.35s;
}
.buttonViewMore {
  margin: 0;
  padding: 0;
}
.btnPrimary {
  background: #f59297;
  border: 2px solid #f59297;
  color: #fff;
}
.btnSecondary {
  background: transparent;
  border: 2px solid #f59297;
  color: #f59297;
}
.btnTertiary {
  border: 2px solid transparent;
  color: #f59297;
  background: transparent;
}
.btnSettings {
  border-bottom: 1px solid rgba(245, 146, 151, 0.7);
  background: transparent;
  border-radius: 0;
  text-align: left;
  display: flex;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  span {
    display: block;
    color: #08272f;
    font-weight: 500;
  }
  svg {
    font-size: 25px;
    margin-right: 10px;
    display: block;
    color: #08272f;
  }
}
.backButton {
  background: transparent;
  width: fit-content;
  height: fit-content;
  padding: 15px;
  border-radius: 5px;
  margin: 0 8px;
  svg {
    display: block;
    color: #f59297;
  }
}
header {
  height: 67vh;
  width: 90%;
  margin: 0 auto;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  h1 {
    position: absolute;
    bottom: 0;
    left: 0;
    text-align: center;
    background: linear-gradient(transparent, #f59297);
    padding: 100px 20px 20px 20px !important;
    box-sizing: border-box;
    color: #fff;
    font-family: "Playfair Display";
    p {
      color: #fff;
      font-size: 14px;
      padding: 0;
      margin-bottom: 0;
      font-family: "Montserrat";
    }
  }
  img {
    width: 100%;
    transform: translate(-50%, -50%);
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    object-fit: cover;
  }
}
@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
  }
}
nav {
  background: #fff;
  text-align: left;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex-shrink: 0;
  box-sizing: border-box;
  margin: 0 auto;

  &.top {
    position: fixed;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 500px;
    text-align: center;
    text-transform: capitalize;
    // padding: 11.5px 0 11.5px 0;
    z-index: 7;
    color: #f59297;
    font-weight: 600;
    font-size: 16px;
    display: flex;
    align-items: center;
    padding: 10px 0;
    span {
      text-overflow: ellipsis;
      overflow: hidden;
      width: calc(100% - 80px);
      margin: 0;
      text-align: left;
    }
    img {
      height: 30px;
      padding: 11px 0;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  &.bottom {
    background: rgba(245, 146, 151, 0.7);
    position: fixed;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    width: calc(100% - 10px);
    max-width: 450px;
    border-radius: 10px;
    box-sizing: border-box;
    // padding: 5px;
    justify-content: space-between;
    z-index: 8;
    backdrop-filter: blur(10px);
    overflow: hidden;
    a {
      width: 19%;
      &.active {
        button {
          transition: 0.35s;
          background: #fff;
          padding: 10px;
          box-sizing: border-box;
          svg {
            color: #f59297;
          }
          small {
            color: #f59297;
          }
        }
      }
      display: block;
      button {
        padding: 10px 5px !important;
        margin: 0;
        font-size: 20px;
        transition: 0.35s;
        width: 100%;
        overflow: hidden;
        border-radius: 0;
        box-sizing: border-box;
        svg {
          display: block;
          color: #fff;
          margin: 0 auto -8px auto;
          font-size: 24px;
        }
        small {
          font-size: 8px;
          font-weight: 600;
        }
      }
    }
  }
}
.page {
  min-height: 85vh;
  max-width: 500px;
  padding: 70px 0;
  box-sizing: border-box;
  animation: pageIntro linear 0.35s;
  transform-origin: bottom;
  overflow: hidden;
  .inputSearch {
    margin-top: 0 !important;
    svg {
      margin-top: 0;
    }
    input {
      margin-top: 0;
    }
  }
}
@keyframes pageIntro {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.aboutSection {
  img {
    min-width: 120px;
    min-height: 120px;
    max-width: 120px;
    max-height: 120px;
    background: #fff;
    object-fit: cover;
    object-position: top;
    border-radius: 50%;
    display: block;
    margin: 0 auto 20px auto;
  }
  h3 {
    padding: 0;
    margin: 0;
    font-family: "Playfair Display";
    font-size: 24px;
  }
  h5 {
    padding: 0;
    margin: 0;
    color: #08272f;
    font-size: 11px;
    font-weight: 500;
  }
}

.stats {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  margin: 10px 0 0 0;
  &.services {
    .statCard {
      min-width: 32.5%;
      max-width: 32.5%;
      width: 30%;
      border: 0;
      position: relative;
      overflow: visible;
      &.two {
        min-width: 49.5%;
        max-width: 49.5%;
        align-items: flex-end;
        justify-content: center;
        overflow: visible;
        h4 {
          position: absolute;
          bottom: -18px;
          padding: 10px 0;
          height: fit-content;
          width: 80%;
          border-radius: 50px;
          background: #fff;
          // margin: 40px 5% 0 5%;
          color: #1761a4 !important;
          opacity: 1;
          box-shadow: 0 2px 6px #ddd;
          margin: 0;
          font-size: 11px;
        }
      }
      a {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
      }
      h5 {
        position: absolute;
        bottom: -10px;
        left: 50%;
        transform: translate(-50%, 0);
        padding: 10px 0;
        height: fit-content;
        width: 80%;
        border-radius: 50px;
        background: #fff;
        // margin: 40px 5% 0 5%;
        color: #1761a4 !important;
        opacity: 1;
        box-shadow: 0 2px 6px #ddd;
        margin: 0;
        font-size: 9px;
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: block;
        }
      }
    }
  }
  .statCard {
    min-width: 30%;
    max-width: 30%;
    width: 30%;
    aspect-ratio: 1/1;
    display: block;
    border: 2px solid #1761a4;
    border-radius: 20px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    overflow: hidden;
    .statValue {
      display: block;
      width: 100%;
      font-size: 20px;
      margin-top: 20px;
      font-family: "Montserrat";
      color: #1761a4;
      font-weight: 800;
    }
    h5 {
      display: block;
      text-align: center;
      width: 100%;
      margin-bottom: 25px;
      position: relative;
      padding: 0;
      color: #08272f;
      opacity: 0.8;
      font-weight: 600;
      font-size: 11px;
    }
  }
}
.input {
  position: relative;
  padding: 5px 0;
  overflow: visible;
  svg {
    position: absolute;
    top: 50%;
    right: 25px;
    transform: translate(-5px, -50%);
    font-size: 25px;
    padding: 0;
    color: #ccc;
    z-index: 300;
    transition: 0.5s;
  }
  &.valid,
  &.filled,
  &.focused,
  &.disabled {
    input,
    textarea,
    select {
      border: 2px solid #08272f;
      &:disabled {
        border: 2px solid #333;
      }
    }
    label {
      top: 6px;
      font-size: 13px;
      border: none;
      font-weight: 600;
      transition: 0.15s linear;
      width: fit-content;
    }
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #1a1b1c inset !important;
    -webkit-text-fill-color: #ffffff !important;
  }
  label {
    font-size: 13px;
    position: absolute;
    top: 50%;
    left: 28px;
    transform: translateY(-50%);
    transition: top 0.3s ease;
    background: #fff;
    display: block;
    padding: 0 3px;
    font-size: 16px;
    font-weight: 500;
    transition: 0.15s linear;
    width: 70%;
    text-align: left;
  }
  input,
  textarea,
  select {
    width: 90%;
    padding: 12px;
    box-sizing: border-box;
    border: 2px solid #bbb;
    border-radius: 10px;
    outline: none;
    transition: border-color 0.3s ease;
    font-size: 16px;
    font-weight: 500;
    background: #fff !important;
    color: #08272f !important;
    resize: none;
    &:disabled {
      border: 2px solid #333;
    }
  }
}
input:disabled {
  &.filled,
  &.focus {
    border: 2px solid #333 !important;
  }
}
option {
  color: #08272f;
}
::placeholder {
  color: #999;
}
.mainContainer {
  height: 100vh;
  min-height: 100vh;
  overflow: auto;
  .container {
    min-height: fit-content;
    height: fit-content;
    max-height: fit-content;
    width: 100%;
    display: block;
    transition: 0.35s;
    h1 {
      padding: 0;
      margin: 0;
      width: 100%;
    }
  }
}

.feedbackCarousel {
  .swiper,
  .swiper-slide {
    position: relative !important;
    // min-height: fit-content !important;
    // max-height: fit-content !important;
    // height: fit-content !important;
  }
  .feedbackCard {
    width: 90%;
    border-radius: 20px;
    margin: 10px auto;
    height: fit-content;
    border: 1px solid #999;
    padding: 20px 0;
    overflow: hidden;
    &.showText {
      p {
        text-overflow: initial;
        overflow: visible;
        display: block;
        transition: 0.35s;
      }
    }
    p {
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      text-align: left;
      padding: 0;
      width: 90%;
      margin: 20px auto;
      transition: 0.35s;
    }
    h5 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      margin: 0 auto;
      span {
        font-weight: 500;
        color: #999;
      }
    }
    .flexStars {
      display: flex;
      align-items: center;
      width: 90%;
      margin: 10px auto;
      svg {
        color: #e6b400;
        font-size: 30px;
      }
    }
  }
}
.swiper-container-free-mode > .swiper-wrapper {
  transition-timing-function: linear;
}
.gallery {
  min-width: 100%;
  .swiper-slide {
    margin: 0;
  }
  .carrouselItem {
    background: transparent;
    min-width: 200px;
    min-height: 200px;
    max-width: 200px;
    max-height: 200px;
    border-radius: 8px;
    margin: 0;
    padding: 0;
    overflow: hidden;
    img {
      min-width: 200px;
      min-height: 200px;
      max-width: 200px;
      max-height: 200px;
      object-fit: cover;
    }
  }
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  height: 100vh;
  width: 100vw;
  .spinner {
    border: 5px solid #ddd;
    border-top: 5px solid #f59297;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.35s linear infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  z-index: 8;
}
.modalNotify {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  max-width: 500px;
  height: fit-content;
  max-height: 90%;
  overflow: auto;
  background: #fff;
  box-shadow: 0 2px 8px #ddd;
  z-index: 9;
  border-radius: 20px;
  padding: 5px;
  box-sizing: border-box;
  .flexStars {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  img {
    border-radius: 15px;
  }
}
.flexGoogleReviews {
  width: fit-content;
  margin: 0 auto 5px auto;
  border: 1px solid #999;
  border-radius: 20px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    border: 1px solid #999;
    padding: 5px;
    border-radius: 10px;
  }
  span {
    margin-left: 10px;
    color: #999;
    text-align: left;
    span {
      font-weight: 600;
      font-size: 16px;
      margin: 0;
    }
    big {
      font-weight: 700;
      color: #999;
      font-size: 16px;
    }
    small {
      font-weight: 600;
      font-size: 12px;
    }
  }
}

//ACCORDION
.accordion {
  border-radius: 20px;
  padding: 10px;
  transition: 0.35s;
  background: #f5f5f5;
  width: 90%;
  margin: 5px auto;
  box-sizing: border-box;
  .accordionButton {
    width: 100%;
    color: #08272f;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    padding: 10px 0;
    text-align: left !important;
    transition: 0.35s;
    svg {
      display: block;
      flex-shrink: 0;
      float: right;
    }
  }
  .accordionContent {
    transition: 0.35s;
    overflow: hidden;
    text-align: left;
    opacity: 0;
  }
  .accordionIcon {
    transition: 0.35s;
    margin-left: 10px;
  }
  .rotate {
    transform: rotate(180deg);
  }
  &.active {
    background: #eee;
    transition: 0.35s;
    .accordionButton {
      color: #08272f;
    }
    .accordionContent {
      opacity: 1;
    }
  }
}
.inputSearch {
  position: relative;
  width: 90%;
  margin: 0 auto;
  input {
    width: 100%;
    padding: 10px 8px 10px 40px;
    // margin: 0;
    box-sizing: border-box;
    border: 2px solid #08272f;
    border-radius: 10px;
  }
  input ~ .inputLabel {
    position: absolute;
    top: 50%;
    left: 40px;
    transform: translate(0, -50%);
    background: #fff;
  }
  input:focus ~ .inputLabel {
    position: absolute;
    top: 0%;
    left: 40px;
    transform: translate(0, -50%);
    background: #fff;
  }
  svg {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 40px;
    height: 39px;
    padding: 8px;
    box-sizing: border-box;
    color: #999;
    margin: 5px 0;
  }
}
.flexHeader {
  display: flex;
  width: 90%;
  margin: 12px auto;
}

.textLeft {
  text-align: left;
  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  transition: 0.35s linear;
  position: relative;
  &.long {
    -webkit-line-clamp: 7;
  }
  span {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 80px;
    width: 100%;
    background: linear-gradient(transparent, #fff);
  }
}
.tabButtonsPrimary {
  display: flex;
  align-items: center;
  justify-content: center;
  .tabButtonPrimary {
    &.active {
      border: 2px solid #08272f;
      color: #08272f !important;
    }
  }
  button:first-of-type {
    margin: 0 5px 0 0;
  }
  button:last-of-type {
    margin: 0 0 0 5px;
  }
  .tabButtonPrimary {
    padding: 8px;
    width: 40vw;
    font-size: 16px;
    border: 2px solid #999;
    color: #999 !important;
  }
}

.btnFlex {
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    margin: 0 10px 0 0;
  }
}

.authError {
  p {
    color: red;
  }
}

.profilePhoto {
  width: 120px;
  min-width: 120px;
  max-width: 120px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  background: #eee;
  display: block;
  margin: 0 auto;
  object-fit: cover;
}

.star {
  display: inline-block;
  color: #999;
  cursor: pointer;
  font-size: 30px;
  margin: 0 0 15px 0;
  svg {
    display: block;
  }
}

.selected {
  color: #e6b400;
}

.deleteButton {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 15px;
  right: 20px;
  svg {
    color: red;
  }
}
.flexButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 55%;
  margin-left: auto;
  padding: 10px;
  button:last-of-type {
    background: red;
  }
  button {
    width: fit-content;
    color: #fff;
    background: #999;
    padding: 3px 8px;
  }
}
mark {
  display: inline;
  padding: 0;
  margin: 0;
  width: fit-content;
  background-color: #f59297;
  color: #fff;
}

// #texto {
//   max-width: 100%;
//   width: 80vw;
//   height: 20vh;
//   stroke-dasharray: 7060;
//   stroke-miterlimit: 5px;
//   animation: 3s animText linear infinite;
// }
#texto {
  width: 100%;
  stroke-dasharray: 7060;
  stroke-dashoffset: 7060;
  animation: dash 5s linear infinite;
  fill: transparent;
  stroke: red;
  stroke-width: 20;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes animText {
  0% {
    stroke-dashoffset: 0;
    background: #ddd;
  }
  100% {
    stroke-dashoffset: 7060;
  }
}
.prices {
  width: 90%;
  padding: 20px 0;
  margin: 0 auto;
  span {
    width: 100%;
    min-width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .strongLeft {
      margin: 0;
    }
    strong {
      margin: 0 0 0 auto;
    }
  }
}
.contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  overflow: auto;
  width: 90%;
  padding: 10px 0;
  .contactItem:last-of-type {
    margin: 0 0 0 0;
  }
  .contactItem {
    width: 32%;
    // background: #f5f5f5;
    border-radius: 20px;
    padding: 15px 0;
    background: #f9f9f9;
    border-radius: 20px;
    padding: 10px;
    margin: 0 5px 0 0;
    &.small {
      width: 19%;
      min-width: 19%;
      max-width: 19%;
      padding: 0;
      background: transparent;
    }
    h5 {
      margin: 0;
      font-size: 11px;
      font-weight: 600;
    }
    svg {
      font-size: 25px;
      margin: 0 0 5px 0;
      color: #f59297;
    }
  }
}
.locationImg {
  width: 90%;
  border-radius: 20px;
}
